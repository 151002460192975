import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { jwtAuthGuard } from '@stlc/angular/guards';
import { ChirpService } from '@stlc/chirp/data-access';
import { isEmpty, isNil } from '@stlc/lodash';

export const chirpPlayerGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    const chirpService = inject(ChirpService);

    return jwtAuthGuard(route, state).pipe(
        switchMap((value) => {
            if (!value) {
                return of(value);
            }
            return chirpService.currentPlayer$.pipe(
                first((player) => !isNil(player) && !isEmpty(player)),
                // If the player is null & empty it will go to catchError
                // else, return true
                map(() => true),
                catchError(() => {
                    chirpService.loadPlayers();
                    return of(false);
                })
            );
        })
    );
};
