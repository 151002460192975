<div class="ui-container">
    <div class="mat-headline-4">{{ 'public:pageNotFound.title' | i18next }}</div>

    <p class="mat-subheading-2 stlc-text-muted">
        {{ 'public:pageNotFound.message' | i18next }}
    </p>
    <button type="button" mat-flat-button color="primary" [routerLink]="['/']">
        {{ 'public:pageNotFound.goBackToHome' | i18next }}
    </button>
</div>
