import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';

import { StlcI18nModule } from '@stlc/i18n/core';

@Component({
    selector: 'chirp-forbidden',
    templateUrl: './forbidden.component.html',
    standalone: true,
    imports: [CommonModule, MatButtonModule, StlcI18nModule],
})
export class ChirpForbiddenComponent implements OnInit {
    url = '/';

    private readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        this.url = this.route.snapshot.queryParams['url'] || '/';
    }
}
