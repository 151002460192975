<div *ngIf="chirpService?.definition$ | async as definition" [ngSwitch]="!definition" class="container mx-auto h-full">
    <div *ngSwitchCase="true" class="flex justify-center pt-4">
        <mat-spinner color="primary" [diameter]="50"></mat-spinner>
    </div>
    <div *ngSwitchCase="false" class="flex flex-col space-y-8 pt-8 max-w-screen-md mx-auto h-full">
        <div class="text-center" *ngIf="dismissedNewsFeedItems.length === definition.length">
            <div class="stlc-no-data-found">
                {{ 'chirp:home.youAreAllCaughtUp' | i18next : { defaultValue: 'You are all caught up!' } }}
            </div>
        </div>

        <ng-container *ngFor="let datum of definition">
            <ng-container
                *ngIf="!datum.initiallyDismissed && !dismissedNewsFeedItems.includes(datum.dismissPreferenceKey)"
            >
                <mat-card
                    *ngIf="datum.type === 'info-card'"
                    class="rounded-none sm:rounded-material"
                    class="stlc-dismissible-card"
                >
                    <button
                        *ngIf="datum.dismissible"
                        mat-icon-button
                        color="gray"
                        (click)="dismissCard(datum)"
                        class="stlc-dismiss-button"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-card-header>
                        <mat-card-title
                            *ngIf="datum.titleKey || datum.title"
                            [innerHTML]="
                                datum.titleKey
                                    ? (datum.titleKey | i18next : { defaultValue: datum.title })
                                    : datum.title
                            "
                        ></mat-card-title>
                        <mat-card-subtitle
                            *ngIf="datum.subtitleKey || datum.subtitle"
                            [innerHTML]="
                                datum.subtitleKey
                                    ? (datum.subtitleKey
                                      | i18next : { defaultValue: datum.subtitle, count: datum.subtitleCount })
                                    : datum.subtitle
                            "
                        ></mat-card-subtitle>
                        <mat-card-subtitle *ngIf="!!datum.date && !datum.subtitle">{{
                            datum.date | date : 'longDate'
                        }}</mat-card-subtitle>
                    </mat-card-header>

                    <mat-card-content>
                        <div class="flex flex-col space-y-2">
                            <ng-container *ngFor="let item of datum.items">
                                <div *ngIf="item?.divider"><mat-divider /></div>
                                <div
                                    [ngSwitch]="item.type"
                                    [ngClass]="{
                                        'hover:cursor-pointer hover:bg-gray-50': item?.routerLink,
                                        'hover:cursor-not-allowed': item?.routerLink === ''
                                    }"
                                    [matRipple]
                                    [matRippleDisabled]="!item?.routerLink"
                                    [routerLink]="item?.routerLink"
                                >
                                    <ng-container *ngSwitchCase="'component'" [ngSwitch]="item.name">
                                        <stlc-reviews-game-summary-metrics
                                            *ngSwitchCase="'ReviewsGameSummaryMetrics'"
                                            [type]="item.data.type"
                                            [summary]="item.data.summary"
                                            [goals]="item.data.goals"
                                        ></stlc-reviews-game-summary-metrics>
                                        <stlc-reviews-news-feed-pitching-stats
                                            *ngSwitchCase="'PitchingGameReview'"
                                            [date]="chirpService.pitchingReviewsDate()"
                                            [playerId]="chirpService.selected?.playerId"
                                        ></stlc-reviews-news-feed-pitching-stats>
                                        <practice-ui-homepage
                                            *ngSwitchCase="'PracticeSummary'"
                                            [type]="item.data.type"
                                            [summary]="item.data.summary"
                                        >
                                        </practice-ui-homepage>
                                        <pitch-grip-recently-uploaded-grips
                                            *ngSwitchCase="'RecentlyUploadedGrips'"
                                            [grips]="item.data.grips"
                                        ></pitch-grip-recently-uploaded-grips>
                                        <span *ngSwitchDefault>Unknown Component: {{ item.name }}</span>
                                    </ng-container>
                                    <div *ngSwitchCase="'link'" class="flex items-center justify-center p-2 space-x-2">
                                        <mat-icon>arrow_forward</mat-icon>
                                        <span
                                            [innerHTML]="
                                                item.labelKey
                                                    ? (item.labelKey | i18next : { defaultValue: item.label })
                                                    : item.label
                                            "
                                        ></span>
                                    </div>
                                    <div *ngSwitchDefault>Unknown Item: {{ item.type }}</div>
                                </div>
                            </ng-container>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>
        </ng-container>
        <div class="text-center min-h-72 flex justify-center items-center" *ngIf="dismissedNewsFeedItems.length > 0">
            <button type="button" mat-button color="gray" (click)="resetDismissals()">
                {{ 'chirp:home.showDismissed' | i18next }}
            </button>
        </div>
    </div>
</div>
