// import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

import { jwtAuthGuard } from '@stlc/angular/guards';
import { chirpPlayerGuard } from '@stlc/chirp/core';
import { resolveChirpPlayer, resolveChirpTeam } from '@stlc/chirp/data-access';
import { ChirpApplication } from '@stlc/chirp/lookup';
import { PitchGripApplication } from '@stlc/pitch-grip/shared';
import { authorizedGuard } from '@stlc/user';

import {
    ChirpContainerComponent,
    ChirpForbiddenComponent,
    ChirpHomeComponent,
    ChirpLoginComponent,
    ChirpPageNotFoundComponent,
    ChirpUnauthorizedComponent,
} from './core';

export const appRoutes: Routes = [
    {
        path: '',
        component: ChirpContainerComponent,
        data: {
            i18nextNamespaces: [
                'chirp',
                'common',
                'public',
                'player',
                'ui',
                'pitchType',
                'filters',
                'chart',
                'stat',
                'practices',
                'playerPerformance',
                'advanceScouting',
            ],
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
        canActivate: [chirpPlayerGuard],
        canActivateChild: [jwtAuthGuard],
        children: [
            {
                path: '',
                component: ChirpHomeComponent,
                data: {
                    i18nextNamespaces: ['reviews', 'stat', 'glossary'],
                },
                resolve: {
                    i18next: I18NEXT_NAMESPACE_RESOLVER,
                },
            },
            {
                path: 'forbidden',
                component: ChirpForbiddenComponent,
                data: {
                    title: 'Chirp - Forbidden',
                    i18nextNamespaces: ['public', 'chirp'],
                },
                resolve: {
                    i18next: I18NEXT_NAMESPACE_RESOLVER,
                },
            },
            {
                path: 'player',
                canActivateChild: [authorizedGuard],
                data: {
                    redirect: '/',
                },
                children: [
                    {
                        path: 'profile',
                        resolve: {
                            player: resolveChirpPlayer,
                        },
                        data: {
                            isUserProfile: true,
                        },
                        loadChildren: () => import('@stlc/chirp/player-profile').then(({ routes }) => routes),
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/',
                    },
                ],
            },
            {
                path: 'player',
                canActivateChild: [authorizedGuard],
                data: {
                    redirect: '/',
                },

                children: [
                    {
                        path: 'search',
                        canActivate: [authorizedGuard],
                        loadChildren: () => import('@stlc/search/core').then(({ routes }) => routes),
                        data: {
                            redirect: '/',
                            applications: [ChirpApplication.player.SEARCH],
                        },
                    },
                    {
                        path: ':playerIdUniverse/:playerId',
                        resolve: {
                            player: resolveChirpPlayer,
                        },
                        data: {
                            isUserProfile: false,
                        },
                        loadChildren: () => import('@stlc/chirp/player-profile').then(({ routes }) => routes),
                    },
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: '/',
                    },
                ],
            },
            {
                path: 'advance-scouting',
                data: {
                    i18nextNamespaces: ['advanceScouting', 'stat', 'glossary', 'pitchType', 'filters'],
                    applications: [
                        ChirpApplication.advanceScouting.MATCHUP_EFFECTIVENESS,
                        ChirpApplication.advanceScouting.OPPOSING_BATTERS,
                    ],
                    redirect: '/',
                },
                resolve: {
                    team: resolveChirpTeam,
                    player: resolveChirpPlayer,
                    i18next: I18NEXT_NAMESPACE_RESOLVER,
                },
                canActivate: [authorizedGuard],
                loadChildren: () => import('@stlc/advance-scouting').then(({ routes }) => routes),
            },
            {
                path: 'performance-testing',
                resolve: {
                    player: resolveChirpPlayer,
                },
                loadChildren: () => import('@stlc/chirp/player-performance').then(({ routes }) => routes),
            },
            // {
            //     path: 'performance',
            //     redirectTo: '/performance-testing',
            // },
            {
                path: 'game-reviews',
                data: {
                    i18nextNamespaces: ['gameReviews', 'explainer', 'glossary'],
                },
                resolve: {
                    i18next: I18NEXT_NAMESPACE_RESOLVER,
                    player: resolveChirpPlayer,
                },
                loadChildren: () => import('@stlc/game/reviews/feature-shell').then(({ routes }) => routes),
            },
            {
                path: 'practice',
                resolve: {
                    player: resolveChirpPlayer,
                },
                loadChildren: () => import('@stlc/practice/core').then(({ routes }) => routes),
                data: {
                    redirect: '/',
                },
                canActivate: [authorizedGuard],
            },
            {
                path: 'practice-reviews',
                redirectTo: '/practice',
            },
            {
                path: 'pitch-grips',
                loadChildren: () => import('@stlc/pitch-grip/core').then((m) => m.PitchGripCoreModule),
                data: {
                    applications: [
                        PitchGripApplication.ADMIN,
                        PitchGripApplication.PITCHER,
                        PitchGripApplication.VIEWER,
                    ],
                    redirect: '/',
                },
                resolve: {
                    player: resolveChirpPlayer,
                },
                canActivate: [authorizedGuard],
            },
            {
                path: 'video',
                loadChildren: () => import('@stlc/player-stats').then(({ videoRoutes }) => videoRoutes),
                data: {
                    redirect: '/',
                    applications: [ChirpApplication.player.VIDEO],
                },
                resolve: {
                    player: resolveChirpPlayer,
                },
                canActivate: [authorizedGuard],
            },
        ],
    },
    {
        path: 'login',
        component: ChirpLoginComponent,
        data: {
            i18nextNamespaces: ['public'],
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
    },
    {
        path: 'unauthorized',
        component: ChirpUnauthorizedComponent,
        data: {
            title: 'Chirp - Unauthorized',
            i18nextNamespaces: ['public'],
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
    },
    {
        path: '**',
        component: ChirpPageNotFoundComponent,
        data: {
            title: 'Chirp - Page Not Found',
            i18nextNamespaces: ['public'],
        },
        resolve: {
            i18next: I18NEXT_NAMESPACE_RESOLVER,
        },
    },
];
