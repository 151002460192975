import { Portal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { StlcProtectedSrcDirective } from '@stlc/angular/directives/protected-src';
import { GoogleAnalyticsService } from '@stlc/angular/services';
import { ChirpService } from '@stlc/chirp/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { UiMenuItem } from '@stlc/shared';
import { UiExpandableMenuComponent, UiLanguageSwitcherComponent } from '@stlc/ui';
import { UiAppService, UiHeaderService } from '@stlc/ui/services';
import { UserService } from '@stlc/user';

import { ChirpHeaderComponent } from '../header/header.component';

@Component({
    selector: 'chirp-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        PortalModule,
        RouterModule,
        ChirpHeaderComponent,
        StlcI18nModule,
        StlcProtectedSrcDirective,
        UiExpandableMenuComponent,
        UiLanguageSwitcherComponent,
    ],
})
export class ChirpContainerComponent implements OnInit, OnDestroy {
    @ViewChild('navLeft', { static: true })
    set navLeft(sidenav: MatSidenav) {
        this.headerService.navLeft = sidenav;
    }

    @ViewChild('navRight', { static: true })
    set navRight(sidenav: MatSidenav) {
        this.headerService.navRight = sidenav;
    }

    // @ViewChild('navCenterIconTemplate', { static: true })
    // set navCenterIcon(el: TemplateRef<any>) {
    //     this.navCenterIconPortal = new TemplatePortal(el, this.viewContainerRef);
    // }

    // @ViewChild('navCenterPlayerTemplate', { static: true })
    // set navCenterPlayer(el: TemplateRef<any>) {
    //     this.navCenterPlayerPortal = new TemplatePortal(el, this.viewContainerRef);
    // }

    @ViewChild('navLeftTemplate', { static: true })
    set teamAndPlayerSelectionTemplate(el: TemplateRef<any>) {
        this.headerService.navLeftPortal = new TemplatePortal(el, this.viewContainerRef);
    }

    loading = false;
    selected?: { teamId?: number; playerId?: number };

    // private navCenterIconPortal: Portal<any> | undefined;
    // private navCenterPlayerPortal: Portal<any> | undefined;
    private navCenterPortal: Portal<any> | undefined;

    private readonly destroy = new Subject<void>();

    readonly chirpService = inject(ChirpService);
    readonly headerService = inject(UiHeaderService);
    readonly userService = inject(UserService);
    readonly router = inject(Router);
    readonly appService = inject(UiAppService);
    private readonly gaService = inject(GoogleAnalyticsService);
    private readonly viewContainerRef = inject(ViewContainerRef);

    ngOnInit() {
        this.router.events
            .pipe(
                filter((evt) => evt instanceof NavigationEnd),
                takeUntil(this.destroy)
            )
            .subscribe(() => this.headerService.navLeft?.close());
    }

    // ngAfterViewInit(): void {
    //     this.userService.currentUser$.pipe(take(1)).subscribe((user) => {
    //         if (this.navCenterPlayerPortal && !user?.isPlayer) {
    //             this.navCenterPortal = this.navCenterPlayerPortal;
    //         } else if (this.navCenterIconPortal) {
    //             this.navCenterPortal = this.navCenterIconPortal;
    //         }

    //         if (this.navCenterPortal) {
    //             this.headerService.pushNavCenterPortal(this.navCenterPortal);
    //         }
    //     });
    // }

    ngOnDestroy() {
        if (this.navCenterPortal) {
            this.headerService.removeNavCenterPortal(this.navCenterPortal);
        }
        this.destroy.next();
    }

    teamChange(change: MatSelectChange) {
        this.chirpService.currentTeam = change.value;
        this.gaService.event(
            'change_team',
            change.value
                ? {
                      team_name: change.value?.fullName,
                  }
                : undefined
        );
    }

    playerChange(change: MatSelectChange) {
        this.chirpService.currentPlayer = change.value;
        this.gaService.event(
            'change_player',
            change.value
                ? {
                      player_id: `${change.value.id}`,
                      player_name: `${change.value.firstName} ${change.value.lastName}`,
                  }
                : undefined
        );
    }

    menuItemClick(item: UiMenuItem) {
        this.gaService.event('side_nav', { router_link: item.routerLink });
    }

    sendFeedback() {
        this.gaService.event('send_feedback');
        return true;
    }
}
