import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import dayjs from 'dayjs';
import { filter, Subject, takeUntil } from 'rxjs';

import { ChirpService } from '@stlc/chirp/data-access';
import { StlcI18nModule } from '@stlc/i18n/core';
import { forEach, includes, isEmpty, isNil, pull, remove } from '@stlc/lodash';
import { PitchGripUiModule } from '@stlc/pitch-grip/ui';
import { NewsFeedFragment } from '@stlc/player/data-access';
import { PracticeUiModule } from '@stlc/practice/ui';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ReviewsNewsFeedPitchingStatsComponent } from '@stlc/reviews/core';
import { ReviewsGameSummaryMetricsComponent } from '@stlc/reviews/ui';
import { JsonValue } from '@stlc/shared';
import { UiAppService } from '@stlc/ui/services';
import { UserService } from '@stlc/user';

@Component({
    selector: 'chirp-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        RouterModule,
        PitchGripUiModule,
        PracticeUiModule,
        StlcI18nModule,
        ReviewsGameSummaryMetricsComponent,
        ReviewsNewsFeedPitchingStatsComponent,
    ],
})
export class ChirpHomeComponent implements OnInit, OnDestroy {
    dismissedNewsFeedItems = [];

    private readonly destroy = new Subject<void>();

    readonly appService = inject(UiAppService);
    readonly chirpService = inject(ChirpService);
    private readonly userService = inject(UserService);
    private readonly snackbar = inject(MatSnackBar);
    private readonly i18next = inject(I18NEXT_SERVICE);

    ngOnInit() {
        this.chirpService.definition$
            .pipe(
                filter((data) => !isNil(data)),
                takeUntil(this.destroy)
            )
            .subscribe((values) => {
                forEach(values, (value) => {
                    if (value.forceShow) {
                        this.userService.updatePreference(value.dismissPreferenceKey, '');
                    }
                    this.listenForDismissChange(value.dismissPreferenceKey);
                });
            });
    }

    ngOnDestroy() {
        this.destroy.next();
    }

    listenForDismissChange(key: string) {
        this.userService
            .getPreferenceValue$(key)
            .pipe(takeUntil(this.destroy))
            .subscribe((value: JsonValue) => {
                if (!isEmpty(value) && !isNil(value)) {
                    if (!includes(this.dismissedNewsFeedItems, key)) {
                        this.dismissedNewsFeedItems.push(key);
                    }
                } else {
                    remove(this.dismissedNewsFeedItems, (datum) => datum === key);
                }
            });
    }

    onLoadingChanged(isLoading: boolean) {
        this.appService.loading = isLoading;
    }

    dismissCard(datum: NewsFeedFragment) {
        const { dismissPreferenceKey } = datum;
        this.userService.updatePreference(dismissPreferenceKey, dayjs(new Date()).toISOString());
        this.dismissedNewsFeedItems.push(dismissPreferenceKey);
        const title = datum.titleKey ? this.i18next.t(datum.titleKey, datum.title) : datum.title;
        const snackbarRef = this.snackbar.open(
            this.i18next.t('chirp:home.cardDismissed', { replace: { title } }),
            this.i18next.t('common:undo'),
            {
                duration: 5000,
                panelClass: ['stlc-mat-snack-bar-default'],
                verticalPosition: 'bottom',
            }
        );

        snackbarRef
            .onAction()
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                pull(this.dismissedNewsFeedItems, dismissPreferenceKey);
                this.userService.updatePreference(dismissPreferenceKey, '');
            });
    }

    resetDismissals() {
        forEach(this.dismissedNewsFeedItems, (key) => {
            this.userService.updatePreference(key, '');
        });
        this.dismissedNewsFeedItems = [];
    }
}
