import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';

import { StlcI18nModule } from '@stlc/i18n/core';
import { UiContainerComponent } from '@stlc/ui';

@Component({
    selector: 'chirp-unauthorized',
    templateUrl: './unauthorized.component.html',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, UiContainerComponent, StlcI18nModule],
})
export class ChirpUnauthorizedComponent implements OnInit {
    url = '/';

    private readonly route = inject(ActivatedRoute);

    ngOnInit(): void {
        this.url = this.route.snapshot.queryParams['url'] || '/';
    }
}
