import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ApolloLink, InMemoryCache, NextLink, Operation, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { cloneDeep, getMainDefinition } from '@apollo/client/utilities';
import { Apollo, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLFormattedError } from 'graphql';
import { createClient } from 'graphql-ws';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

import { STLC_DATE_FORMATS, StlcDateAdapter } from '@stlc/angular/material';
import { ChirpAuthService, ChirpUserService, GripMediaUpdateGQL } from '@stlc/chirp/data-access';
import { isNil } from '@stlc/lodash';
import { PITCH_GRIP_MEDIA_UPDATE } from '@stlc/pitch-grip/data-access';
import { JwtAuthInterceptor } from '@stlc/ui';
import { UiAppService, UiHeaderService, UiIconService, UiVersionCheckService } from '@stlc/ui/services';
import { JwtAuthService, UserService } from '@stlc/user';

import { ChirpRouteReuseStrategy } from './core/route-reuse-strategy';

// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
// https://stackoverflow.com/a/52803291
// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-465305186
function stripTypenames(obj: any, propToDelete: string) {
    for (const property in obj) {
        if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
            delete obj.property;
            const newData = stripTypenames(obj[property], propToDelete);
            obj[property] = newData;
        } else {
            if (property === propToDelete) {
                delete obj[property];
            }
        }
    }
    return obj;
}

const removeTypenameMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
    if (operation.variables) {
        operation.variables = stripTypenames(cloneDeep(operation.variables), '__typename');
        return forward ? forward(operation) : null;
    }

    return null;
});

export const appProviders: Provider[] = [
    Title,
    UiAppService,
    UiHeaderService,
    UiVersionCheckService,
    {
        provide: UserService,
        useClass: ChirpUserService,
    },
    {
        provide: JwtAuthService,
        useClass: ChirpAuthService,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: (iconService: UiIconService) => () => iconService.registerIcons(),
        deps: [UiIconService],
        multi: true,
    },
    {
        provide: MAT_RIPPLE_GLOBAL_OPTIONS,
        useValue: {
            color: 'rgba(66, 126, 230, 0.08)',
        },
    },
    {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useFactory: () => ({
            ...new MatDialogConfig(),
            autoFocus: false,
            backdropClass: 'stlc-dialog-backdrop',
            scrollStrategy: new NoopScrollStrategy(),
            position: {
                top: '2.5vh',
            },
            maxWidth: '90vw',
            maxHeight: '90vh',
        }),
    },
    {
        provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
        useValue: {
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastButtons: true,
        },
    },
    {
        provide: DateAdapter,
        useClass: StlcDateAdapter,
        deps: [MAT_DATE_LOCALE, Platform],
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: STLC_DATE_FORMATS,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtAuthInterceptor,
        multi: true,
    },
    Apollo,
    {
        provide: APOLLO_NAMED_OPTIONS,
        useFactory: (httpLink: HttpLink, appService: UiAppService, authService: JwtAuthService): NamedOptions => {
            const wsHost = window.location.host;
            const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            const wsLink = new GraphQLWsLink(
                createClient({
                    url: `${wsProtocol}//${wsHost}/graphql`,
                    keepAlive: 25000,
                    shouldRetry: () => true,
                    // TODO add logic to use refresh token if access token is expired or denied
                    connectionParams: async () => {
                        const token = await firstValueFrom(
                            authService.accessToken$.pipe(filter((accessToken) => !isNil(accessToken)))
                        );
                        return {
                            isWebSocket: true,
                            headers: {
                                authorization: token ? `Bearer ${token}` : '',
                            },
                        };
                    },
                })
            );

            const errorLink: any = onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach((err: GraphQLFormattedError & { code?: string }) => {
                        if (err?.code !== 'FORBIDDEN') {
                            if (err.message.startsWith('Error: Timeout: Request failed to complete')) {
                                appService.error = 'Request timed out. Contact support if problem persists.';
                            } else {
                                appService.error = err.message;
                            }
                            return false;
                        }
                        return true;
                    });
                }
                if (networkError) {
                    console.error(networkError);
                    appService.error = `Network Error: Unable to connect`;
                }
            });

            const link = split(
                // split based on operation type
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
                },
                wsLink,
                ApolloLink.from([
                    errorLink,
                    removeTypenameMiddleware,
                    httpLink.create({
                        uri: '/graphql',
                    }),
                ])
            );

            return {
                default: {
                    cache: new InMemoryCache(),
                    link,
                    connectToDevTools: true,
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: 'network-only',
                            errorPolicy: 'ignore',
                        },
                        query: {
                            fetchPolicy: 'network-only',
                            errorPolicy: 'all',
                        },
                    },
                },
                gateway: {
                    cache: new InMemoryCache(),
                    link: ApolloLink.from([
                        errorLink,
                        removeTypenameMiddleware,
                        httpLink.create({
                            uri: '/graphql-gateway',
                        }),
                    ]),
                    connectToDevTools: true,
                    defaultOptions: {
                        watchQuery: {
                            fetchPolicy: 'network-only',
                            errorPolicy: 'ignore',
                        },
                        query: {
                            fetchPolicy: 'network-only',
                            errorPolicy: 'all',
                        },
                    },
                },
            };
        },
        deps: [HttpLink, UiAppService, JwtAuthService],
    },
    {
        provide: PITCH_GRIP_MEDIA_UPDATE,
        useClass: GripMediaUpdateGQL,
    },
    { provide: RouteReuseStrategy, useClass: ChirpRouteReuseStrategy },
];
