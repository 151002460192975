import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GoogleAnalyticsService } from '@stlc/angular/services';
import { StlcI18nModule } from '@stlc/i18n/core';
import { UiContainerComponent } from '@stlc/ui';
import { UiLanguageSwitcherComponent } from '@stlc/ui';
import { UiAppService } from '@stlc/ui/services';
import { JwtAuthService } from '@stlc/user';

@Component({
    selector: 'chirp-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        StlcI18nModule,
        UiContainerComponent,
        UiLanguageSwitcherComponent,
    ],
})
export class ChirpLoginComponent implements OnInit, OnDestroy {
    loading = false;

    readonly url = '/login/tw';

    private readonly destroy = new Subject<void>();

    constructor(
        public appService: UiAppService,
        private route: ActivatedRoute,
        private jwtAuthService: JwtAuthService,
        private gaService: GoogleAnalyticsService,
        private router: Router
    ) {
        const redirect = this.route.snapshot.queryParams['url'];
        if (redirect) {
            this.url += `?url=${redirect}`;
        }
    }

    ngOnInit() {
        combineLatest([this.route.queryParams, this.gaService.initialized$])
            .pipe(takeUntil(this.destroy))
            .subscribe(([params]) => {
                if (params['accessToken']) {
                    this.appService.subtitles = [];
                    this.loading = true;
                    this.jwtAuthService.accessToken = params['accessToken'];

                    const id = this.jwtAuthService.id;
                    if (id) {
                        this.gaService.user({ id });
                    }
                    const url = this.route.snapshot.queryParams['url'] ?? '/';
                    this.gaService.event('login', {
                        method: 'jwt',
                        page_path: '/login',
                        page_location: window.location.href.split('?')[0],
                        redirect_path: url,
                    });
                    this.router.navigateByUrl(url);
                } else {
                    this.appService.subtitles = ['Sign In'];
                    this.loading = false;
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }

    loginClicked(event: MouseEvent) {
        if (event.currentTarget instanceof HTMLAnchorElement) {
            this.loading = true;
            window.location.href = event.currentTarget.href;
        }
    }
}
