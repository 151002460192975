<ngx-loading-bar ref="router" color="#427ee6"></ngx-loading-bar>
<router-outlet></router-outlet>

<div *ngIf="appService.loading$ | async" class="absolute top-0 left-0 right-0 h-full z-100">
    <div id="loading-overlay" class="fixed bottom-0 left-0 right-0 stlc-overlay-bg"></div>
    <div id="loading-spinner" class="fixed top-40 bottom-0 left-0 right-0">
        <div class="flex justify-center items-center shadow bg-gray-50 mx-auto w-20 h-20 rounded-full">
            <mat-spinner diameter="50"></mat-spinner>
        </div>
    </div>
</div>
