import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

import { PlayerFragment } from '@stlc/player/data-access';

@Injectable()
export class ChirpRouteReuseStrategy implements BaseRouteReuseStrategy {
    private handlers: { [key: string]: DetachedRouteHandle } = {};

    /**
     * Determines if this route (and its subtree) should be detached to be reused later
     * @param route
     */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        if (!route.routeConfig || route.routeConfig.loadChildren) {
            return false;
        }
        /** Whether this route should be re used or not */
        let shouldReuse = false;
        if (route.routeConfig.data) {
            route.routeConfig.data['reuseRoute'] ? (shouldReuse = true) : (shouldReuse = false);
        }

        return shouldReuse;
    }

    /**
     * Stores the detached route.
     */
    store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
        if (handler) {
            this.handlers[this.getUrl(route)] = handler;
        }
    }

    /**
     * Determines if this route (and its subtree) should be reattached
     * @param route Stores the detached route.
     */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.handlers[this.getUrl(route)];
    }

    /**
     * Retrieves the previously stored route
     */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        if (!route.routeConfig || route.routeConfig.loadChildren) {
            return null;
        }

        return this.handlers[this.getUrl(route)];
    }

    /**
     * Determines if a route should be reused
     * @param future
     * @param current
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        /** We only want to reuse the route if the data of the route config contains a reuse true boolean */
        let reuseUrl = false;

        if (future.routeConfig) {
            if (future.routeConfig.data) {
                reuseUrl = future.routeConfig.data['reuse'];
            }
        }

        /**
         * Default reuse strategy by angular assers based on the following condition
         * @see https://github.com/angular/angular/blob/4.4.6/packages/router/src/route_reuse_strategy.ts#L67
         */
        const defaultReuse = future.routeConfig === current.routeConfig;

        // If either of our reuseUrl and default Url are true, we want to reuse the route
        return reuseUrl || defaultReuse;
    }

    clear() {
        this.handlers = {};
    }

    private getUrl(route: ActivatedRouteSnapshot) {
        let player: PlayerFragment | undefined;
        let ref: ActivatedRouteSnapshot | null = route;
        while (ref) {
            if (!player) {
                player = ref.data['player'];
            }

            if (player) {
                break;
            }
            ref = ref.parent;
        }

        const url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');

        return `${player ? `${player.id}:` : ''}${url}`;
    }
}
