<mat-toolbar class="bg-white mat-elevation-z1 overflow-hidden whitespace-nowrap">
    <div class="flex-1 flex items-center" [ngSwitch]="headerService.showBackButton$ | async">
        <ng-container *ngSwitchCase="true" [ngSwitch]="headerService.useSmartBackButton$ | async">
            <button
                *ngSwitchCase="true"
                mat-icon-button
                (click)="headerService.navigationService.back()"
                title="Back"
                aria-label="Back"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button
                *ngSwitchCase="false"
                mat-icon-button
                [routerLink]="headerService.backButtonLink$ | async"
                title="Back"
                aria-label="Back"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngSwitchCase="false">
            <button
                mat-icon-button
                title="{{ 'common:menu' | i18next }}"
                aria-label="Menu"
                *ngIf="headerService.navLeftPortal$ | async"
                (click)="headerService.toggleNavLeft()"
            >
                <mat-icon>menu</mat-icon>
            </button>
        </ng-container>
    </div>
    <!-- <ng-template [cdkPortalOutlet]="headerService.navCenterPortal$ | async"></ng-template> -->
    <ng-container *ngIf="headerService.title$ | async as title; else noTitle">
        <div class="text-ellipsis overflow-clip">{{ title }}</div>
    </ng-container>

    <div class="flex-1 flex justify-end">
        <ng-template [cdkPortalOutlet]="headerService.navRightToggle$ | async"></ng-template>
        <button
            mat-icon-button
            *ngIf="searchService.showSearchButton$ | async"
            (click)="searchService.openSearchDialog()"
        >
            <mat-icon>search</mat-icon>
        </button>
    </div>
</mat-toolbar>

<ng-template #noTitle>
    <ng-container *ngIf="!isUserCurrentPlayer || player !== currentPlayer; else noPlayer">
        <div class="flex space-x-2 items-baseline">
            <div>{{ player.firstName }} {{ player.lastName }}</div>
            <div class="text-gray-400 text-base font-normal">
                {{ player.primaryPosition || '-' }} | {{ player.bats || '-' }} / {{ player.throws || '-' }}
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #noPlayer>
    <mat-icon
        *ngIf="currentTeam.levelCode !== 'lrk'"
        [svgIcon]="'stlc-' + (currentTeam?.levelCode || 'mlb') + '-' + (currentTeam?.teamCode || 'sln') + '-logo'"
        class="h-8 w-8"
    ></mat-icon>
    <mat-icon *ngIf="currentTeam.levelCode === 'lrk'" svgIcon="stlc-mlb-sln-logo" class="h-8 w-8"></mat-icon>
</ng-template>
