import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { I18NEXT_SERVICE } from 'angular-i18next';
import { Subject } from 'rxjs';
import { first, switchMap, take, takeUntil } from 'rxjs/operators';

import { GoogleAnalyticsService } from '@stlc/angular/services';
import { isEmpty } from '@stlc/lodash';
import { UiAppService, UiVersionCheckService } from '@stlc/ui/services';
import { UserService } from '@stlc/user';

@Component({
    selector: 'chirp-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, LoadingBarModule, LoadingBarRouterModule, MatSnackBarModule, MatProgressSpinnerModule],
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly destroy = new Subject<void>();

    readonly appService = inject(UiAppService);
    readonly userService = inject(UserService);
    private readonly snackBar = inject(MatSnackBar);
    private readonly gaService = inject(GoogleAnalyticsService);
    private readonly versionCheck = inject(UiVersionCheckService);
    private readonly i18next = inject(I18NEXT_SERVICE);
    // private readonly document: Document = inject(DOCUMENT);
    // private readonly renderer = inject(Renderer2);

    ngOnInit(): void {
        this.appService.title = 'Chirp';

        this.appService.error$.pipe(takeUntil(this.destroy)).subscribe((error) => {
            this.snackBar.open(error, 'Dismiss', {
                duration: undefined,
                verticalPosition: 'top',
                panelClass: ['stlc-mat-snack-bar-danger'],
            });
        });

        this.gaService.initialized$
            .pipe(
                take(1),
                switchMap(() => this.userService.currentUser$),
                first((user) => !isEmpty(user))
            )
            .subscribe((user) => {
                const additionalProperties = {
                    user_team: user.isPlayer ? user.teamworksPreferredTeam : undefined,
                    user_team_level: user.isPlayer ? user.teamworksPreferredTeamLevel : undefined,
                    ebis_id: user.isPlayer ? user.ebisId : undefined,
                    is_player: user.isPlayer,
                    is_catcher: user.isPlayer && user.isCatcher ? true : undefined,
                    is_infielder: user.isPlayer && user.isInfielder ? true : undefined,
                    is_outfielder: user.isPlayer && user.isOutfielder ? true : undefined,
                    is_pitcher: user.isPlayer && user.isPitcher ? true : undefined,
                    is_position_player: user.isPlayer && user.isPositionPlayer ? true : undefined,
                };
                this.gaService.user(user, additionalProperties);
            });

        this.versionCheck
            .monitorVersion()
            .pipe(takeUntil(this.destroy))
            .subscribe((changed) => this.reloadApp(changed));

        // this.appService.isHandsetPortrait$.pipe(takeUntil(this.destroy)).subscribe((value) => {
        //     if (value) {
        //         this.renderer.addClass(this.document.body, 'stlc-is-handset-portrait');
        //     } else {
        //         this.renderer.removeClass(this.document.body, 'stlc-is-handset-portrait');
        //     }
        // });
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }

    private reloadApp(changed: boolean) {
        const message = changed
            ? this.i18next.t('chirp:versionUpdatedReload', {
                  defaultValue: 'Chirp has been updated! Please reload for the latest version.',
              })
            : this.i18next.t('chirp:loadErrorReload', {
                  defaultValue: 'Please reload the page to complete your request.',
              });

        this.versionCheck.reloadApp(message);
    }
}
