<div class="container mx-auto h-full">
    <div class="flex items-center justify-center">
        <div class="flex flex-col items-center space-y-10 mt-10 mx-4">
            <h1
                [innerHTML]="
                    'chirp:forbidden.header'
                        | i18next: { defaultValue: 'Sorry! You do not have access to view this page' }
                "
            ></h1>
            <div
                class="flex flex-col items-center text-gray-400 text-lg"
                [innerHTML]="
                    'chirp:forbidden.help'
                        | i18next
                            : {
                                  defaultValue:
                                      'If you have any questions please ask someone from the Player Development staff for assistance.'
                              }
                "
            ></div>
            <div class="flex flex-col items-center space-y-8">
                <a mat-flat-button color="primary" href="/" class="w-60">
                    {{ 'public:pageNotFound.goBackToHome' | i18next }}
                </a>
                <a mat-button color="primary" href="mailto:ChirpSupport@cardinals.com?subject=Chirp" class="w-60">
                    {{ 'public:message.needHelp' | i18next }}
                </a>
            </div>
        </div>
    </div>
</div>
