<ui-container>
    <div container-content class="flex items-center justify-center mt-8">
        <div class="flex flex-col text-center items-center space-y-16">
            <ui-language-switcher color="gray" buttonType="stroked"></ui-language-switcher>
            <div class="flex flex-col items-center justify-center space-y-4">
                <mat-icon svgIcon="stlc-mlb-sln-logo-dark" class="h-16 w-16"></mat-icon>
            </div>
            <a
                mat-flat-button
                color="primary"
                [attr.href]="url"
                class="relative"
                [disabled]="loading"
                (click)="loginClicked($event)"
            >
                <mat-spinner
                    *ngIf="loading"
                    class="absolute top-0 left-0 right-0 bottom-0 m-auto"
                    diameter="28"
                ></mat-spinner>
                {{ 'public:login.signInWith' | i18next : { value: 'Teamworks' } }}
            </a>
            <a
                mat-button
                color="primary"
                href="mailto:ChirpSupport@cardinals.com?subject=Chirp - Sign In Help"
                [disabled]="loading"
                >{{ 'public:message.needHelp' | i18next }}</a
            >
        </div>
    </div>
</ui-container>
