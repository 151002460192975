import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { StlcI18nModule } from '@stlc/i18n/core';

@Component({
    selector: 'chirp-page-not-found',
    templateUrl: './page-not-found.component.html',
    standalone: true,
    imports: [RouterModule, MatButtonModule, StlcI18nModule],
})
export class ChirpPageNotFoundComponent {}
