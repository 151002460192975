import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { Subject } from 'rxjs';

import { GoogleAnalyticsService } from '@stlc/angular/services';
import { StlcI18nModule, StlcI18nService } from '@stlc/i18n/core';
import { PlayerFragment } from '@stlc/player/data-access';
import { SearchService } from '@stlc/search/data-access';
import { TeamFragment } from '@stlc/team/data-access';
import { UiAppService, UiHeaderService } from '@stlc/ui/services';

@Component({
    selector: 'chirp-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        PortalModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        StlcI18nModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChirpHeaderComponent implements OnChanges, OnDestroy {
    @Input()
    currentTeam: TeamFragment | undefined;

    @Input()
    searchedPlayer: PlayerFragment | undefined;

    @Input()
    currentPlayer: PlayerFragment | undefined;

    @Input()
    isUserCurrentPlayer: boolean;

    player: PlayerFragment | undefined;

    private readonly destroy = new Subject<void>();

    readonly headerService = inject(UiHeaderService);
    readonly languageService = inject(StlcI18nService);
    readonly appService = inject(UiAppService);
    readonly searchService = inject(SearchService);
    private readonly gaService = inject(GoogleAnalyticsService);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchedPlayer'] || changes['currentPlayer']) {
            this.player = this.searchedPlayer ?? this.currentPlayer;
        }
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }

    navActionsShown() {
        this.gaService.event('show_nav_actions');
    }
}
