<ui-container>
    <div container-content class="flex items-center justify-center">
        <div class="flex flex-col items-center space-y-20 mt-20">
            <div class="flex justify-center">
                <mat-icon svgIcon="stlc-mlb-sln-logo" class="h-16 w-16"></mat-icon>
            </div>
            <div class="text-gray-400 text-lg">
                {{ 'public:unauthorized.message' | i18next }}
            </div>
            <div class="flex flex-col items-center space-y-8">
                <a mat-flat-button color="primary" href="/login" class="w-60">{{
                    'public:unauthorized.tryAgain' | i18next
                }}</a>
                <a
                    mat-button
                    color="primary"
                    href="mailto:ChirpSupport@cardinals.com?subject=Chirp - Sign In Help"
                    class="w-60"
                >
                    {{ 'public:message.needHelp' | i18next }}
                </a>
            </div>
        </div>
    </div>
</ui-container>
