import 'dayjs/locale/es';
import 'dayjs/locale/ko';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/core/components';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, appConfig)
    .then(() => {
        dayjs.extend(LocalizedFormat);
    })
    .catch((err) => console.error(err));
