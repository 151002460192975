import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { StlcI18nModule } from '@stlc/i18n/core';

import { appProviders } from './app.providers';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(appRoutes, withComponentInputBinding()),
        importProvidersFrom(MatDialogModule, MatSnackBarModule, StlcI18nModule.forRoot(['en', 'es', 'ko'])),
        appProviders,
    ],
};
