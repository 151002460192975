<mat-sidenav-container hasBackdrop="true" class="h-full max-h-full">
    <mat-sidenav #navLeft class="w-80" position="start" fixedInViewport="true" autoFocus="false" mode="over">
        <div class="flex justify-end mr-2">
            <button mat-icon-button (click)="navLeft.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <ng-template [cdkPortalOutlet]="headerService.navLeftPortal$ | async"></ng-template>
    </mat-sidenav>
    <mat-sidenav #navRight class="w-80 pt-2" position="end" fixedInViewport="true" autoFocus="false" mode="over">
        <div class="flex justify-end mr-2">
            <button mat-icon-button (click)="navRight.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <ng-template [cdkPortalOutlet]="headerService.navRightPortal$ | async"></ng-template>
    </mat-sidenav>
    <mat-sidenav-content class="flex flex-col">
        <div class="density-1">
            <chirp-header
                class="print:hidden"
                [isUserCurrentPlayer]="chirpService.isUserCurrentPlayer$ | async"
                [currentTeam]="chirpService.currentTeam$ | async"
                [currentPlayer]="chirpService.currentPlayer$ | async"
                [searchedPlayer]="chirpService.searchedPlayer$ | async"
            ></chirp-header>
        </div>
        <main class="flex-1">
            <router-outlet></router-outlet>
        </main>
        <footer class="print:hidden pt-16">
            <mat-toolbar class="justify-center h-12 bg-transparent border-b-2 border-gray-50">
                <span class="text-gray-400 text-sm">&copy; St. Louis Cardinals, LLC</span>
            </mat-toolbar>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #navCenterIconTemplate>
    <ng-container *ngIf="chirpService.currentTeam$ | async as currentTeam">
        <mat-icon
            *ngIf="currentTeam.levelCode !== 'lrk'"
            [svgIcon]="'stlc-' + (currentTeam?.levelCode || 'mlb') + '-' + (currentTeam?.teamCode || 'sln') + '-logo'"
            class="h-8 w-8"
        ></mat-icon>
        <mat-icon *ngIf="currentTeam.levelCode === 'lrk'" svgIcon="stlc-mlb-sln-logo" class="h-8 w-8"></mat-icon>
    </ng-container>
</ng-template>

<ng-template #navCenterPlayerTemplate>
    <ng-container *ngIf="chirpService.searchedPlayer$ | async as player">
        <div class="flex space-x-2 items-baseline">
            <div>{{ player.firstName }} {{ player.lastName }}</div>
            <div class="text-gray-400 text-base font-normal">
                {{ player.primaryPosition || '-' }} | {{ player.bats || '-' }} / {{ player.throws || '-' }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="(chirpService.searchedPlayer$ | async) === undefined">
        <ng-container *ngIf="chirpService.currentPlayer$ | async as player">
            <div class="flex space-x-2 items-baseline">
                <div>{{ player.firstName }} {{ player.lastName }}</div>
                <div class="text-gray-400 text-base font-normal">
                    {{ player.primaryPosition || '-' }} | {{ player.bats || '-' }} / {{ player.throws || '-' }}
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #navLeftTemplate>
    <div class="flex-1 flex flex-col items-center">
        <div class="flex justify-around px-2 space-x-2 w-full">
            <ng-template [ngTemplateOutlet]="playerAndTeamTemplate"></ng-template>
        </div>
        <mat-divider class="w-11/12 mt-4"></mat-divider>
        <div class="flex-1 w-full">
            <ng-template [ngTemplateOutlet]="menuListTemplate"></ng-template>
        </div>
        <div class="flex flex-col items-center space-y-8 mt-4 mb-8">
            <ui-language-switcher color="gray" buttonType="stroked"></ui-language-switcher>
            <a
                mat-button
                color="primary"
                href="mailto:ChirpSupport@cardinals.com?subject=Chirp - Feedback"
                class="w-48"
                (click)="sendFeedback()"
                [disabled]="loading"
                ><span [innerHTML]="'public:sendFeedback' | i18next"></span>
            </a>
            <button
                type="button"
                mat-flat-button
                color="primary"
                [disabled]="loading"
                (click)="loading = true; userService.logout()"
                class="w-48"
            >
                <mat-spinner
                    *ngIf="loading"
                    class="absolute top-0 left-0 right-0 bottom-0 m-auto"
                    diameter="28"
                ></mat-spinner>
                <span [innerHTML]="'public:signOut' | i18next"></span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #playerAndTeamTemplate>
    <img
        *ngIf="chirpService.currentPlayer$ | async as player"
        [stlcProtectedSrc]="
            '/img/players/' +
            player.sourceIdUniverse +
            '/' +
            player.sourceId +
            '/headshot/' +
            player.headshot.imageFilename +
            '?hash=' +
            player.headshot.hash
        "
        class="rounded-xl"
        height="75"
        width="50"
    />
    <div class="flex-1 flex flex-col density-4">
        <mat-form-field
            class="stlc-mat-form-field-unstyled"
            subscriptSizing="dynamic"
            [ariaReadOnly]="(chirpService.teamCount$ | async) <= 1"
            [ngSwitch]="(chirpService.teamCount$ | async) > 1"
        >
            <mat-select
                *ngSwitchCase="true"
                (selectionChange)="teamChange($event)"
                [value]="chirpService.currentTeam$ | async"
                panelClass="max-h-screen-80"
            >
                <mat-select-trigger *ngIf="chirpService.currentTeam$ | async as currentTeam">
                    {{ currentTeam?.fullName }}
                </mat-select-trigger>
                <mat-option *ngFor="let team of chirpService.teams$ | async" [value]="team">{{
                    team.fullName
                }}</mat-option>
            </mat-select>
            <ng-container *ngSwitchCase="false">
                <input
                    matInput
                    *ngIf="chirpService.currentTeam$ | async as team"
                    [value]="team?.fullName"
                    [readonly]="true"
                />
            </ng-container>
        </mat-form-field>
        <mat-form-field
            class="stlc-mat-form-field-unstyled"
            subscriptSizing="dynamic"
            [ariaReadOnly]="(chirpService.playerCount$ | async) <= 1"
            [ngSwitch]="(chirpService.playerCount$ | async) > 1"
        >
            <mat-select
                *ngSwitchCase="true"
                (selectionChange)="playerChange($event)"
                [value]="chirpService.currentPlayer$ | async"
                panelClass="max-h-screen-50 w-60"
            >
                <mat-select-trigger *ngIf="chirpService.currentPlayer$ | async as player">
                    <span class="truncate"> {{ player?.lastName }}, {{ player?.firstName }} </span>
                    <span class="ml-1 text-xs text-gray-400">{{ player?.primaryPosition }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let datum of chirpService.players$ | async" [value]="datum"
                    ><span class="truncate">{{ datum.lastName }}, {{ datum.firstName }}</span>
                    <span class="ml-1 text-xs text-gray-400">{{ datum.primaryPosition }}</span></mat-option
                >
            </mat-select>
            <ng-container *ngSwitchCase="false">
                <input
                    matInput
                    *ngIf="chirpService.currentPlayer$ | async as player"
                    [value]="player?.lastName + ', ' + player?.firstName"
                    [readonly]="true"
                />
            </ng-container>
        </mat-form-field>
    </div>
</ng-template>

<ng-template #menuListTemplate>
    <ui-expandable-menu [menuItems]="chirpService.menuItems$ | async" [collapseOnLeave]="true"></ui-expandable-menu>
</ng-template>
